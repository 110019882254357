.business {
  padding: 40px 0;
  text-align: center;
}

.business__heading {
  color: var(--color-700);
  font-weight: 700;

  @include media-tablet {
    font-size: 14px;
  }
}

.business__list {
  display: flex;
  flex-direction: column;

  @include media-tablet {
    flex-direction: row;
    margin-top: 24px;
  }
}

.business__item {
  padding: 16px;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 8px;

  border-bottom: 1px solid var(--color-100);

  &:last-child {
    border-bottom: 0;
  }

  @include media-tablet {
    padding: 40px;
    border-bottom: 0;
    width: calc(50%);

    border-right: 1px solid var(--color-100);
    text-align: right;

    &:last-child {
      text-align: left;
      border-right: 0;

      .business__description {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }

  @include media-desktop {
    padding: 80px 64px;
  }
}

.business__title {
  font-weight: 700;
  font-size: 22px;
  color: var(--color-1000);

  @include media-tablet {
    font-size: 36px;
  }

  @include media-desktop {
    font-size: 48px;
  }
}

.business__subtitle,
.business__description {
  color: var(--color-700);
}

.business__description {
  @include media-tablet {
    margin-top: 16px;
    max-width: 280px;
    margin-left: auto;
  }

  @include media-desktop {
    margin-top: 24px;
  }
}

:root {
  --color-white: #ffffff;
  --color-dark: #000c1a;
  --color-blue-light: #0063cc;
  --color-blue-dark: #003166;
  --color-violet: #004a99;
  --color-light: #434343;
  --color-muted: #160f0f;

  --color-100: #eceef1;
  --color-200: #f2f6fd;
  --color-300: #e7eef5;
  --color-400: #ced5e2;
  --color-500: #f1f4fa;
  --color-600: #9ba5b4;
  --color-700: #546072;
  --color-800: #41454b;
  --color-850: #3d4856;
  --color-900: #343c47;
  --color-1000: #21262d;

  --color-main-600: #0063cc;
  --color-main-700: #003166;

  --font-family: 'PT Root UI', sans-serif;
  --font-family-serif: 'IBM Plex Sans', serif;

  --line-height: 1.5;

  --height-header: 56px;

  --transition: all ease-in-out 0.3s;

  --width-container: 940px;

  --z-index-header: 10;
  --z-index-aside: 10;
}

.logo {
  display: block;
  height: 48px;
}

header {
  top: 0;
  left: 0;
  z-index: 500;
  // position: fixed;
  width: 100%;
  color: hsl(0deg 0% 7% / 80%);
  z-index: var(--z-index-header);
  height: var(--height-header);
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.header__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__menu {
  @include btn-reset;

  width: 32px;
  height: 32px;
  position: relative;

  @include media-tablet {
    display: none;
  }
}

header nav {
  position: fixed;
  height: calc(100vh - 56px);
  top: 56px;
  right: 0;
  width: 100vw;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  background: var(--color-white);

  @include media-tablet {
    align-items: baseline;
    position: unset;
    width: auto;
    height: auto;
    flex-direction: row;
    gap: 8px;
  }

  a {
    color: var(--color-1000);
    font-weight: 700;
    font-size: 28px;
    position: relative;
    height: 35px;
    line-height: 35px;
    padding: 0 14px;
    border-radius: 2px;

    &:hover {
      color: var(--color-blue-light);
    }

    &.active {
      background: #e5f2ff;
    }

    @include media-tablet {
      color: var(--color-700);
      font-weight: 500;
      font-size: 18px;
    }

    sup {
      position: absolute;
      top: 7px;
      right: 6px;
      height: 7px;
      width: 8px;

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.header__icon{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.author {
  display: flex;
  flex-shrink: 0;
  gap: 12px;
  color: var(--color-light);
  margin-bottom: 16px;

  @include media-tablet {
    // order: 3;
    flex-direction: row;
  }
}

.author__avatar {
  width: 48px;
  height: 48px;
  background: #E7E2D3;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-radius: 2px;
  border-radius: 50%;
  overflow: hidden;
}

.author__name, .author__address {
  font-size: 14px;
  @include media-tablet {
    font-size: 16px;
  }
}

.author__content {
  @include media-tablet {
    // text-align: right;
  }
}

.reviews {
  background: #f7f5ef;
  padding: 40px 0 64px;

  @include media-desktop {
    padding: 80px 0 128px;
  }
}

.reviews__heading {
  margin-bottom: 24px;

  @include media-tablet {
    margin-bottom: 32px;
  }

  @include media-desktop {
    margin-bottom: 64px;
  }
}

.reviews__header {
  display: flex;
  gap: 4px;
  align-items: center;
}

.reviews__item {
  // background: var(--color-white);
  // border-radius: 4px;
  // margin-bottom: 16px;
  // padding: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  // gap: 16px;

  @include media-tablet {
    background: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    // padding: 40px;
  }

  @include media-desktop {
    margin-bottom: 20px;
    gap: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.reviews__quote {
  font-weight: 700;
  color: var(--color-1000);
  position: relative;
  font-size: 22px;
  line-height: 1.15;
  margin-bottom: 10px;

  @include media-tablet {
    font-size: 36px;
  }

  @include media-desktop {
    // width: 45%;
    font-weight: 700;
    font-size: 48px;
    // line-height: 115%;
    // margin-bottom: -24px;
  }

  // &::before,
  // &::after {
  //   font-family: var(--font-family-serif);
  //   color: #66b0ff;
  //   font-size: 36px;
  //   line-height: 60%;
  //   position: absolute;
  // }

  // &::before {
  //   content: '“';
  //   left: -18px;
  // }

  // &::after {
  //   content: '”';
  //   bottom: -22px;
  // }
}

.reviews__text {
  font-size: 14px;
  color: var(--color-light);
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: clip;
  overflow: hidden;
  margin-bottom: 12px;
  max-height: 40px;
  transition: var(--transition);

  @include media-tablet {
    // width: 50%;
    font-size: 16px;
    align-self: flex-end;
    -webkit-line-clamp: unset;
  }

  @include media-desktop {
    // padding-left: 40px;
  }

  p + p {
    margin-top: 24px;
  }
}

.reviews__item--collapsed .reviews__text {
  -webkit-line-clamp: unset;
  overflow: unset;
  max-height: max-content;
}

.reviews__more {
  @include btn-reset();
  text-align: left;
  font-weight: 500;
  color: var(--color-main-600);

  &:hover {
    color: var(--color-main-700);
  }

  @include media-tablet {
    display: none;
  }
}

sup {
  vertical-align: super;
  font-size: smaller;
}

h1 {
  font-weight: bold;
  font-size: 28px;
  line-height: 1.2;

  @include media-tablet {
    font-size: 48px;
  }

  @include media-desktop {
    font-size: 64px;
  }
}

h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;

  @include media-tablet {
    font-size: 36px;
  }

  @include media-desktop {
    font-size: 48px;
  }
}

h3 {
  font-size: 24px;

  @include media-tablet {
    font-size: 36px;
  }

  @include media-desktop {
    font-size: 48px;
  }
}

h4 {
  font-weight: bold;

  @include media-tablet {
    font-size: 24px;
  }
}

h5 {
  font-weight: 500;

  @include media-tablet {
    font-size: 24px;
  }
}

h6 {
  font-weight: bold;
}

.small-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.15;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

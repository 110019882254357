@import './reset.scss';
@import './vendor.scss';
@import './variables.scss';

@import './mixins/media.scss';
@import './mixins/buttons.scss';

@import '../assets/fonts/pt-root-ui/stylesheet.css';
@import '../assets/fonts/ibm-plex-sans/stylesheet.css';

@import './common/base.scss';
@import './common/type.scss';
@import './common/container.scss';
@import './common/forms.scss';

@import './components/header.scss';
@import './components/hero.scss';
@import './components/business.scss';
@import './components/services.scss';
@import './components/work.scss';
@import './components/partners.scss';

@import './components/cases.scss';
@import './components/reviews.scss';
@import './components/author.scss';
@import './components/rate.scss';
@import './components/team.scss';
@import './components/member.scss';
@import './components/why.scss';
@import './components/contact.scss';
@import './components/footer.scss';
@import './components/modals.scss';

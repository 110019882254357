.member {
  flex-shrink: 0;
  background: var(--color-white);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform-origin: 50% 200%;
  transition: var(--transition);

  &:nth-child(1) {
    transform: translateX(-30%) rotate(-32deg) scale(0.95);
    z-index: 2;

    .member__img,
    .member__content {
      opacity: 0.2;
    }
  }

  &:nth-child(2) {
    left: 50.5%;
    transform: translateX(-10%) rotate(-25deg);
    z-index: 3;

    .member__img,
    .member__content {
      opacity: 0.3;
    }
  }

  &:nth-child(3) {
    transform: translateX(-40%) rotate(-10deg);
    z-index: 4;

    .member__img,
    .member__content {
      opacity: 0.7;
    }
  }

  &:nth-child(4) {
    transform: translateX(-50%) rotate(0deg);
    text-align: center;
    z-index: 5;
  }

  &:nth-child(5) {
    transform: translateX(-55%) rotate(10deg);
    text-align: right;
    z-index: 4;

    .member__img,
    .member__content {
      opacity: 0.7;
    }
  }

  &:nth-child(6) {
    transform: translateX(-90%) rotate(25deg);
    text-align: right;
    z-index: 3;

    .member__img,
    .member__content {
      opacity: 0.3;
    }
  }

  &:nth-child(7) {
    transform: translateX(-70%) rotate(32deg) scale(0.95);
    text-align: right;
    z-index: 2;

    .member__img,
    .member__content {
      opacity: 0.2;
    }
  }
}

.team__members:hover {
  .member {
    transform-origin: 50% 260%;

    .member__img,
    .member__content {
      opacity: 1;
    }
  }
}

.member__content {
  transition: var(--transition);

  padding: 16px;
  font-size: 14px;
  @include media-tablet {
    font-size: 16px;
  }
}

.member__name {
  color: var(--color-1000);
  margin-bottom: 4px;
  font-weight: 700;
}

.member__job {
  color: var(--color-900);
}

.member__img {
  transition: var(--transition);

  width: 140px;
  height: 210px;

  @include media-tablet {
    width: 180px;
    height: 270px;
  }
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Medium.eot');
    src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
        url('IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexSans-Medium.woff2') format('woff2'),
        url('IBMPlexSans-Medium.woff') format('woff'),
        url('IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Italic.eot');
    src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
        url('IBMPlexSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexSans-Italic.woff2') format('woff2'),
        url('IBMPlexSans-Italic.woff') format('woff'),
        url('IBMPlexSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-Regular.eot');
    src: local('IBM Plex Sans Regular'), local('IBMPlexSans-Regular'),
        url('IBMPlexSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexSans-Regular.woff2') format('woff2'),
        url('IBMPlexSans-Regular.woff') format('woff'),
        url('IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('IBMPlexSans-MediumItalic.eot');
    src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
        url('IBMPlexSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexSans-MediumItalic.woff2') format('woff2'),
        url('IBMPlexSans-MediumItalic.woff') format('woff'),
        url('IBMPlexSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


.modals {
  position: fixed;
  width: 300vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100vh;
  top: 0;
  z-index: 10;
  background: var(--color-200);
}

.modal__close {
  @include btn-reset();

  display: block;
  z-index: 20;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 12px;
  left: 12px;
  border-radius: 50%;
  background: #ffffff;
}

.modal {
  line-height: 1.4;
  font-size: 14px;
  color: var(--color-1000);
  background: var(--color-200);
  position: relative;
  padding: 64px;
  cursor: default;
  height: 100vh;
  overflow-y: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 10;
    background: linear-gradient(
      to bottom,
      var(--color-200) 0%,
      var(--color-200) 50%,
      #ffffff00 100%
    );
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-main-600);
    border-radius: 8px;
  }

  @include media-tablet {
    font-size: 16px;
  }
}

.modal__wrapper {
  height: 100vh;
  position: relative;
  cursor: zoom-out;
  background: #00000020;
}

.modal__heading {
  margin: 32px 0 8px;
  font-size: 22px;

  @include media-tablet {
    font-size: 36px;
    margin: 32px 0 20px;
  }
}

.modal__aside {
  order: 1;
  display: grid;
  gap: 24px;
  padding: 20px 0 32px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: var(--color-100);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;

  @include media-tablet {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    border-width: 0 0 0 1px;
    padding: 0 40px;
    margin: 0 40px;
    bottom: 0;
    gap: 32px;
    grid-auto-rows: max-content;
  }
}

.modal__description {
  order: 0;
  @include media-tablet {
    font-size: 24px;
  }
}

.modal__title {
  color: var(--color-700);
  margin-bottom: 8px;
}

.modal__text + .modal__text {
  margin-top: 4px;
}

.modal__paragraph {
  margin-top: 20px;

  @include media-tablet {
    margin-top: 32px;
  }
}

.modal__main {
  display: flex;
  flex-direction: column;
  position: relative;

  @include media-tablet {
    display: block;
    padding-right: 40%;
  }
}

.modal__content {
  order: 3;
  //   margin-bottom: 40px;
}

.modal__gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include media-tablet {
    padding-top: 40px;
  }

  @include media-desktop {
    padding-top: 80px;
  }
}

.modal__img {
  cursor: zoom-in;
  width: calc(50% - 10px);

  @include media-tablet {
    width: calc(25% - 20px);
  }

  &.x2 {
    display: none;

    @include media-tablet {
      display: block;
      width: calc(50% - 20px);
    }
  }

  img {
    display: block;
  }
}

.next {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 0;
}

.next__label {
  display: flex;
  align-items: center;
  color: var(--color-800);
  gap: 4px;
  margin-bottom: 4px;
}

.next__title {
  font-weight: 700;
  font-size: 16px;
  color: var(--color-main-600);
  transition: var(--transition);
}

.next:hover {
  .next__title {
    color: var(--color-main-700);
  }
}

.gallery {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px;
  z-index: 20;
  cursor: zoom-out;
}

.gallery__bg {
  background: #00000050;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

#galleryContent {
  position: relative;
  z-index: 2;
  display: block;
  max-height: 100%;
  max-width: 100%;
}

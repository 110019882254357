.work {
  background: var(--color-500);
  padding: 40px 0 64px;

  @include media-desktop {
    padding: 80px 0 128px;
  }
}

.work__header {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.work__subheading {
  color: var(--color-700);
}

.work__list {
  @include media-tablet {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
  
  @include media-desktop {
    gap: 32px;
    transform: translateY(-5%);
  }
}

.work__item {
  padding-top: 40px;
  cursor: pointer;
  @include media-tablet {
    padding-top: 0;
  }
}

.work__top {
  position: relative;
  z-index: 2;
}

.work__title {
  margin-bottom: 12px;
}

.work__description {
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @include media-tablet {
    margin-bottom: 24px;
  }
}

.work__img-wrap {
  margin-bottom: 16px;

  @include media-tablet {
    margin-bottom: 24px;
  }

  @include media-desktop {
    overflow: hidden;
  }
}

.work__img {
  @include media-desktop {
    transition: var(--transition);
    transform: translateY(10%);
  }
}

.work__button {
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--color-main-600);

  span {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      width: 100%;
      transform: scaleX(0);
      background: var(--color-main-600);
      transition: var(--transition);
    }
  }
}

.work__item:hover {
  .work__img {
    transform: translateY(0);
  }
  .work__button {
    span {
      position: relative;
      &::before {
        transform: scaleX(100%);
      }
    }
  }
}

.partners {
  padding: 40px 0;
  overflow: hidden;
}

.partners__header {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
}

.partners__heading {
  color: var(--color-700);
}

.partners__list {
  display: flex;
  width: calc(300px * 10 * 2);
//   gap: 80px;
}

.partners__item {
  flex-shrink: 0;
  width: 300px;
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(10 * -300px), 0, 0);
  }
}

.partners__list {
    animation: scroll 30s linear infinite;
}

fieldset {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;

  @include media-tablet {
    gap: 8px;
    margin-bottom: 24px;
  }

  // @include media-desktop {
    // margin-bottom: 32px;
  // }
}

label {
  font-size: 14px;
  color: var(--color-1000);

  @include media-tablet {
    font-size: 16px;
  }
}

input,
textarea {
  color: var(--color-1000);
  height: 48px;
  padding: 12px 16px;
  background: #f2f2f5;
  border-radius: 4px;
  outline: none;
  border: 2px solid #f2f2f5;
  font-size: 16px;
  font-family: var(--font-family);
  transition: var(--transition);

  &:hover,
  &:active,
  &:focus {
    border-color: rgba(137, 140, 171, 0.25);
  }

  &:active,
  &:focus {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 6px rgba(19, 30, 47, 0.1);
  }
}

textarea {
  resize: vertical;
  min-height: 160px;
  width: 100% !important;
}

.rate {
  margin: 30px 0;
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  @include media-tablet {
    margin: 0;
    border-top: 0;
    border-bottom: 0;
    padding: 0 0 0 48px;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
  }
  // padding-bottom: 45px;
  // background: linear-gradient(
  //   rgba(255, 255, 255, 0) 0%,
  //   rgba(51, 151, 255, 0.5) 50%,
  //   rgba(255, 255, 255, 0) 100%
  // );

  // @include media-tablet {
  //   padding-bottom: 100px;
  //   background: radial-gradient(
  //     rgba(51, 151, 255, 0.5) 0%,
  //     rgba(255, 255, 255, 0) 50%
  //   );
  // }
}

// .rate__top {
//   text-align: center;
//   margin-bottom: 32px;

//   @include media-tablet {
//     margin-bottom: 40px;
//   }

//   @include media-desktop {
//     margin-bottom: 75px;
//   }
// }

.rate__list {
  display: grid;
  align-items: flex-end;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;

  @include media-tablet {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 1fr);
    gap: 32px;
  }
  @include media-desktop {
    gap: 48px;
  }
}

.rate__item {
  text-align: center;

  @include media-tablet {
    text-align: left;
  }
  // background: var(--color-white);
  // display: flex;
  // justify-content: space-between;
  // padding: 16px;
  // color: var(--color-violet);

  // @include media-tablet {
  //   flex-direction: column-reverse;
  //   align-items: flex-start;
  //   gap: 24px;
  //   padding: 24px;
  //   width: 100%;
  // }

  // @include media-desktop {
  //   padding: 30px 40px 80px;
  // }
}

.rate__title {
  margin-bottom: 4px;
  color: var(--color-700);
  line-height: 1.3;

  @include media-tablet {
    font-size: 14px;
  }
  @include media-desktop {
    // font-size: 24px;
    // margin-bottom: 0px;
  }
}

.rate__text {
  font-weight: 400;
  font-size: 22px;

  @include media-tablet {
    font-size: 24px;
  }

  @include media-desktop {
    font-size: 36px;
  }
}

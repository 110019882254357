.services {
  overflow-x: hidden;
  user-select: none;

  @include media-tablet {
    user-select: unset;
  }
}

.services__wrap {
  position: relative;
  height: 300px;
}

.services__slides {
  position: absolute;
  display: flex;

  // grid-template-columns: repeat(3, 33%);
  gap: 8px;
  justify-content: space-between;
  // padding-left: calc(15vw - 16px);
  transition: var(--transition);

  @include media-tablet {
    position: relative;
    // gap: 20px;
  }

  @include media-desktop {
  }
}

.services__item {
  position: relative;
  background: var(--color-white);
  border-radius: 0px 0px 4px 4px;
  width: 70vw;
  transition: var(--transition);
  background: var(--color-200);
  border-radius: 4px;

  @include media-tablet {
    width: auto;
    background: none;
  }
}

.services__icon {
  width: 16px;
  
  @include media-tablet {
    width: 24px;
  }
}

.services__header {
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  border-bottom: 1px solid var(--color-300);

  @include media-tablet {
    padding: 0;
    height: auto;
    border-bottom: 0;
    margin-bottom: 20px;
  }
}

.services__title {
  color: var(--service-color);
  height: 100%;
  line-height: 40px;
  position: relative;

  @include media-tablet {
    font-size: 14px;
    line-height: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -32px;
    right: 0;
    height: 1px;
    background: var(--service-color);

    @include media-tablet {
      display: none;
    }
  }
}

.services__list {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  li {
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
}

.services__content {
  color: var(--color-700);
  padding: 16px;
  font-size: 14px;

  @include media-tablet {
    font-size: 16px;
    padding: 0;
  }
}

.services__group-title {
  margin-bottom: 4px;
}

.services__dots {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  @include media-tablet {
    display: none;
  }
}

.services__dot {
  @include btn-reset();
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--color-400);

  &.active {
    background: var(--color-800);
  }
}

.cases {
  margin-top: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 8px;
  font-size: 14px;

  @include media-tablet {
    padding: 20px 32px;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  @include media-desktop {
    grid-template-columns: 1fr 1fr;
  }
}

.cases__heading {
  color: var(--color-muted);
  margin-bottom: 16px;
}

.cases__list {
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex-wrap: wrap;

  @include media-tablet {
    flex-direction: row;
  }
}

.cases__item {
  display: flex;
  gap: 12px;
  align-items: center;

  @include media-tablet {
    width: calc(50% - 12px);
  }
}

.cases__title {
  color: var(--color-muted);
}

.contact {
  position: relative;
  z-index: 5;
  // color: var(--color-white);
  // background: #001933;
  padding: 40px 0;

  @include media-tablet {
    padding: 40px 0 120px;
  }

  @include media-desktop {
    padding: 120px 0;
  }
}

.contact__container {
  @include media-tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

// .contact__icon {
//   width: 24px;

//   @include media-tablet {
//     width: 32px;
//   }
// }

.contact__heading {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.contact__subheading {
  color: var(--color-700);
}

.contact__top {
  margin-bottom: 40px;
}

.contact__text {
  font-size: 22px;
  
  @include media-tablet {
    font-size: 36px;
  }

  @include media-desktop {
    font-size: 48px;
  }
}

.contact__form {
  // flex-grow: 1;
  // padding: 16px 0;

  @include media-tablet {
    // padding: 24px;
    // margin-left: auto;
    // max-width: 50%;
  }

  @include media-desktop {
    // width: 100%;
    // margin-top: 56px;
    // max-width: 460px;
  }
}

.contact__button {
  @include btn-reset;

  background: var(--color-main-600);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  font-size: 16px;
  font-family: var(--font-family);
  color: var(--color-white);
  border: 1px solid var(--color-main-600);

  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 160px;
  font-weight: bold;

  margin-top: 40px;

  &:hover {
    background: var(--color-white);
    color: var(--color-main-600);
  }
}

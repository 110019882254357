@mixin media-mobile {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height);
  color: var(--color-base);
  overflow-x: hidden;
  // padding-top: var(--height-header);
}

html {
  overflow-x: hidden;
}

html,
body {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--color-blue-light);
  }
}

button,
a {
  font-size: inherit;
  cursor: pointer;
  font-family: var(--font-family);
  transition: var(--transition);

  &:active {
    transform: translate(0px, 1px);
  }
}

img {
  max-width: 100%;
}

::selection {
  background: var(--color-blue-dark);
  color: var(--color-white);
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  background: var(--color-blue-light);
  border-radius: 8px;
}

footer {
  background: var(--color-1000);
  color: var(--color-600);
  padding: 48px 0;
  font-size: 14px;
  line-height: 1.4;

  @include media-tablet {
    font-size: 16px;
    padding: 80px 0 48px;
  }
}

.footer__address {
  line-height: 1.55;
}

.footer__content {
  margin: 16px 0;
  display: flex;
  display: grid;
  gap: 48px;
  grid-template-rows: auto auto;

  @include media-tablet {
    grid-template-rows: auto;
    grid-template-columns: auto auto;
  }
}

.footer__links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @include media-tablet {
    gap: 0;
    justify-items: end;
  }
}

.footer__link {
  color: var(--color-white);
}

.footer__nav {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ffffff04;
  border-bottom: 1px solid #ffffff04;
  padding: 30px 0;
  gap: 16px;
  margin: 30px 0;

  @include media-tablet {
    flex-grow: 1;
    flex-direction: row;
    padding: 0;
    border: 0;
    margin: 0;
    justify-content: flex-end;
  }
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  @include media-tablet {
    flex-grow: 1;
    flex-direction: row;
  }
}

.footer__bottom {
  @include media-tablet {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border-top: 1px solid #ffffff04;
    padding-top: 24px;
    margin-top: 120px;
  }
}

.hero {
  img {
    border-radius: 12px;
    margin-bottom: 16px;
    box-shadow: 0px 10px 20px 4px #0000001a;
  }
  @include media-desktop {
    margin-bottom: 120px;
  }
}

.hero__content {
  width: 100%;
  max-width: 490px;
  margin: 56px auto 36px;
  padding: 48px 16px 0;
  background: url('../assets/img/hero/bg.svg') no-repeat center top;
  text-align: center;

  @include media-tablet {
    margin-top: 80px;
    padding: 80px 16px 56px;
  }

  @include media-desktop {
    max-width: 640px;
    margin-top: 100px;
    margin-bottom: 75px;
    padding: 96px 16px 0;
  }
}

.hero__heading {
  color: var(--color-dark);
  line-height: 1.15;
  margin-bottom: 16px;

  @include media-tablet {
    margin-bottom: 22px;
  }
}

.hero__text {
  color: var(--color-light);

  @include media-tablet {
    font-size: 18px;
  }
  @include media-desktop {
    font-size: 24px;
  }
}

.hero__mobile {
  column-count: 2;
  column-gap: 16px;

  padding-left: 16px;
  padding-right: 16px;

  text-align: center;

  @include media-tablet {
    display: none;
  }
}

.hero__tablet {
  display: none;

  width: 100%;

  padding-left: 16px;
  padding-right: 16px;

  margin: 0 auto;

  @include media-tablet {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media-desktop {
    display: none;
  }
}

.hero__tablet-right {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 3;
}

.hero__tablet-bottom {
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
}

.hero__tablet-center {
  align-self: end;
  text-align: center;
  justify-self: center;
}

.hero__tablet-left {
  text-align: right;
}

.hero__slides {
  display: none;
  text-align: center;

  @include media-desktop {
    display: block;
    overflow: hidden;
    position: relative;
    height: 700px;
  }
}

.hero__desktop {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  width: 1660px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto;
}

.hero__desktop-first {
  grid-column-start: 1;
  grid-column-end: 3;
}

.hero__desktop-center {
  padding-top: 32px;
}

.hero__desktop-bottom {
  padding-top: 64px;
}

.why {
  position: relative;
  padding: 40px 0;

  @include media-desktop {
    padding: 120px 0px;
  }
}

.why__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  // height: 100%;
  min-height: 100vh;
  // bottom: 0;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    // bottom: 0;
    height: 500px;
    z-index: 1;
    background: linear-gradient(
      to right,
      #ffffff 5%,
      #00000000 25%,
      #00000000 50%,
      #00000000 75%,
      #ffffff 95%
    );
  }
}

.why__container {
  position: relative;
  z-index: 1;
  // @include media-tablet {
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
  // }
}

.why__content {
  display: flex;
  flex-direction: column-reverse;

  @include media-tablet {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 30px 0 0;
  }

  @include media-desktop {
    padding: 40px 0;
  }
}

.why__header {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;

  @include media-tablet {
    margin-bottom: 12px;
  }
}

.why__subheading {
  color: var(--color-850);
}

.why__heading {
  margin-bottom: 24px;
}

.why__text {
  // color: #434343;

  @include media-tablet {
    padding-right: 40px;
    //   padding: 16px 0px;
    //   max-width: 400px;
  }

  @include media-desktop {
    padding-right: 80px;
  }

  // @include media-tablet {
  //   max-width: 460px;
  // }

  p + p {
    margin-top: 16px;
  }

  // em {
  //   font-family: var(--font-family-serif);
  //   font-weight: 500;
  //   font-style: italic;
  // }
}

@font-face {
  font-family: PT Root UI;
  src: url("PTRootUI-Medium.79879a3f.eot");
  src: local(PT Root UI Medium), local(PTRootUI-Medium), url("PTRootUI-Medium.79879a3f.eot#iefix") format("embedded-opentype"), url("PTRootUI-Medium.eb15f426.woff2") format("woff2"), url("PTRootUI-Medium.a9360b71.woff") format("woff"), url("PTRootUI-Medium.fe591366.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: PT Root UI;
  src: url("PTRootUI-Regular.5c447f3d.eot");
  src: local(PT Root UI), local(PTRootUI-Regular), url("PTRootUI-Regular.5c447f3d.eot#iefix") format("embedded-opentype"), url("PTRootUI-Regular.75e476ac.woff2") format("woff2"), url("PTRootUI-Regular.d1f9a7ad.woff") format("woff"), url("PTRootUI-Regular.e21cdaf5.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PT Root UI;
  src: url("PTRootUI-Bold.459b21ba.eot");
  src: local(PT Root UI Bold), local(PTRootUI-Bold), url("PTRootUI-Bold.459b21ba.eot#iefix") format("embedded-opentype"), url("PTRootUI-Bold.584a23bd.woff2") format("woff2"), url("PTRootUI-Bold.3efb9716.woff") format("woff"), url("PTRootUI-Bold.668276d4.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex Sans;
  src: url("IBMPlexSans-Medium.e5443890.eot");
  src: local(IBM Plex Sans Medium), local(IBMPlexSans-Medium), url("IBMPlexSans-Medium.e5443890.eot#iefix") format("embedded-opentype"), url("IBMPlexSans-Medium.2b03fb6f.woff2") format("woff2"), url("IBMPlexSans-Medium.79d88449.woff") format("woff"), url("IBMPlexSans-Medium.5ed5c9f7.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Sans;
  src: url("IBMPlexSans-Italic.dac0fe7d.eot");
  src: local(IBM Plex Sans Italic), local(IBMPlexSans-Italic), url("IBMPlexSans-Italic.dac0fe7d.eot#iefix") format("embedded-opentype"), url("IBMPlexSans-Italic.c7670c26.woff2") format("woff2"), url("IBMPlexSans-Italic.1f94f753.woff") format("woff"), url("IBMPlexSans-Italic.aeaae57a.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Sans;
  src: url("IBMPlexSans-Regular.ee692279.eot");
  src: local(IBM Plex Sans Regular), local(IBMPlexSans-Regular), url("IBMPlexSans-Regular.ee692279.eot#iefix") format("embedded-opentype"), url("IBMPlexSans-Regular.e1de3c8a.woff2") format("woff2"), url("IBMPlexSans-Regular.f22e8df1.woff") format("woff"), url("IBMPlexSans-Regular.3e21eb3a.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Sans;
  src: url("IBMPlexSans-MediumItalic.c7215823.eot");
  src: local(IBM Plex Sans Medium Italic), local(IBMPlexSans-MediumItalic), url("IBMPlexSans-MediumItalic.c7215823.eot#iefix") format("embedded-opentype"), url("IBMPlexSans-MediumItalic.5d9b740c.woff2") format("woff2"), url("IBMPlexSans-MediumItalic.a5697b5a.woff") format("woff"), url("IBMPlexSans-MediumItalic.cf3679cf.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body, html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.splide--draggable > .splide__slider > .splide__track, .splide--draggable > .splide__track {
  user-select: inherit;
}

:root {
  --color-white: #fff;
  --color-dark: #000c1a;
  --color-blue-light: #0063cc;
  --color-blue-dark: #003166;
  --color-violet: #004a99;
  --color-light: #434343;
  --color-muted: #160f0f;
  --color-100: #eceef1;
  --color-200: #f2f6fd;
  --color-300: #e7eef5;
  --color-400: #ced5e2;
  --color-500: #f1f4fa;
  --color-600: #9ba5b4;
  --color-700: #546072;
  --color-800: #41454b;
  --color-850: #3d4856;
  --color-900: #343c47;
  --color-1000: #21262d;
  --color-main-600: #0063cc;
  --color-main-700: #003166;
  --font-family: "PT Root UI", sans-serif;
  --font-family-serif: "IBM Plex Sans", serif;
  --line-height: 1.5;
  --height-header: 56px;
  --transition: all ease-in-out .3s;
  --width-container: 940px;
  --z-index-header: 10;
  --z-index-aside: 10;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height);
  color: var(--color-base);
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

html, body {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--color-blue-light);
}

button, a {
  font-size: inherit;
  cursor: pointer;
  font-family: var(--font-family);
  transition: var(--transition);
}

button:active, a:active {
  transform: translate(0, 1px);
}

img {
  max-width: 100%;
}

::selection {
  background: var(--color-blue-dark);
  color: var(--color-white);
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  background: var(--color-blue-light);
  border-radius: 8px;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 64px;
  }
}

h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 768px) {
  h2 {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  h2 {
    font-size: 48px;
  }
}

h3 {
  font-size: 24px;
}

@media (min-width: 768px) {
  h3 {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  h3 {
    font-size: 48px;
  }
}

h4 {
  font-weight: bold;
}

@media (min-width: 768px) {
  h4 {
    font-size: 24px;
  }
}

h5 {
  font-weight: 500;
}

@media (min-width: 768px) {
  h5 {
    font-size: 24px;
  }
}

h6 {
  font-weight: bold;
}

.small-title {
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.15;
}

.container {
  width: 100%;
  max-width: calc(var(--width-container)  + 32px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

fieldset {
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
  display: flex;
}

@media (min-width: 768px) {
  fieldset {
    gap: 8px;
    margin-bottom: 24px;
  }
}

label {
  color: var(--color-1000);
  font-size: 14px;
}

@media (min-width: 768px) {
  label {
    font-size: 16px;
  }
}

input, textarea {
  color: var(--color-1000);
  height: 48px;
  font-size: 16px;
  font-family: var(--font-family);
  transition: var(--transition);
  background: #f2f2f5;
  border: 2px solid #f2f2f5;
  border-radius: 4px;
  outline: none;
  padding: 12px 16px;
}

input:hover, input:active, input:focus, textarea:hover, textarea:active, textarea:focus {
  border-color: #898cab40;
}

input:active, input:focus, textarea:active, textarea:focus {
  background: #fff;
  box-shadow: inset 0 4px 6px #131e2f1a;
}

textarea {
  resize: vertical;
  min-height: 160px;
  width: 100% !important;
}

.logo {
  height: 48px;
  display: block;
}

header {
  z-index: 500;
  width: 100%;
  color: #121212cc;
  z-index: var(--z-index-header);
  height: var(--height-header);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #ffffffe6;
  border-bottom: 1px solid #0000000d;
  top: 0;
  left: 0;
}

.header__container {
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__menu {
  width: 32px;
  height: 32px;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  position: relative;
}

@media (min-width: 768px) {
  .header__menu {
    display: none;
  }
}

header nav {
  height: calc(100vh - 56px);
  width: 100vw;
  background: var(--color-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
  position: fixed;
  top: 56px;
  right: 0;
}

@media (min-width: 768px) {
  header nav {
    position: unset;
    width: auto;
    height: auto;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
  }
}

header nav a {
  color: var(--color-1000);
  height: 35px;
  border-radius: 2px;
  padding: 0 14px;
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  position: relative;
}

header nav a:hover {
  color: var(--color-blue-light);
}

header nav a.active {
  background: #e5f2ff;
}

@media (min-width: 768px) {
  header nav a {
    color: var(--color-700);
    font-size: 18px;
    font-weight: 500;
  }
}

header nav a sup {
  height: 7px;
  width: 8px;
  position: absolute;
  top: 7px;
  right: 6px;
}

header nav a sup svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.header__icon {
  position: absolute;
  inset: 0;
}

.hero img {
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 10px 20px 4px #0000001a;
}

@media (min-width: 1024px) {
  .hero {
    margin-bottom: 120px;
  }
}

.hero__content {
  width: 100%;
  max-width: 490px;
  text-align: center;
  background: url("bg.5695a5ac.svg") top no-repeat;
  margin: 56px auto 36px;
  padding: 48px 16px 0;
}

@media (min-width: 768px) {
  .hero__content {
    margin-top: 80px;
    padding: 80px 16px 56px;
  }
}

@media (min-width: 1024px) {
  .hero__content {
    max-width: 640px;
    margin-top: 100px;
    margin-bottom: 75px;
    padding: 96px 16px 0;
  }
}

.hero__heading {
  color: var(--color-dark);
  margin-bottom: 16px;
  line-height: 1.15;
}

@media (min-width: 768px) {
  .hero__heading {
    margin-bottom: 22px;
  }
}

.hero__text {
  color: var(--color-light);
}

@media (min-width: 768px) {
  .hero__text {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  .hero__text {
    font-size: 24px;
  }
}

.hero__mobile {
  column-count: 2;
  text-align: center;
  column-gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 768px) {
  .hero__mobile {
    display: none;
  }
}

.hero__tablet {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  display: none;
}

@media (min-width: 768px) {
  .hero__tablet {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .hero__tablet {
    display: none;
  }
}

.hero__tablet-right {
  grid-row: 1 / 3;
  grid-column-start: 3;
}

.hero__tablet-bottom {
  text-align: center;
  grid-column: 1 / 3;
}

.hero__tablet-center {
  text-align: center;
  place-self: end center;
}

.hero__tablet-left {
  text-align: right;
}

.hero__slides {
  text-align: center;
  display: none;
}

@media (min-width: 1024px) {
  .hero__slides {
    height: 700px;
    display: block;
    position: relative;
    overflow: hidden;
  }
}

.hero__desktop {
  width: 1660px;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.hero__desktop-first {
  grid-column: 1 / 3;
}

.hero__desktop-center {
  padding-top: 32px;
}

.hero__desktop-bottom {
  padding-top: 64px;
}

.business {
  text-align: center;
  padding: 40px 0;
}

.business__heading {
  color: var(--color-700);
  font-weight: 700;
}

@media (min-width: 768px) {
  .business__heading {
    font-size: 14px;
  }
}

.business__list {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .business__list {
    flex-direction: row;
    margin-top: 24px;
  }
}

.business__item {
  background: var(--color-white);
  border-bottom: 1px solid var(--color-100);
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  display: flex;
}

.business__item:last-child {
  border-bottom: 0;
}

@media (min-width: 768px) {
  .business__item {
    width: 50%;
    border-bottom: 0;
    border-right: 1px solid var(--color-100);
    text-align: right;
    padding: 40px;
  }

  .business__item:last-child {
    text-align: left;
    border-right: 0;
  }

  .business__item:last-child .business__description {
    margin-left: unset;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .business__item {
    padding: 80px 64px;
  }
}

.business__title {
  color: var(--color-1000);
  font-size: 22px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .business__title {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  .business__title {
    font-size: 48px;
  }
}

.business__subtitle, .business__description {
  color: var(--color-700);
}

@media (min-width: 768px) {
  .business__description {
    max-width: 280px;
    margin-top: 16px;
    margin-left: auto;
  }
}

@media (min-width: 1024px) {
  .business__description {
    margin-top: 24px;
  }
}

.services {
  user-select: none;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .services {
    user-select: unset;
  }
}

.services__wrap {
  height: 300px;
  position: relative;
}

.services__slides {
  transition: var(--transition);
  justify-content: space-between;
  gap: 8px;
  display: flex;
  position: absolute;
}

@media (min-width: 768px) {
  .services__slides {
    position: relative;
  }
}

.services__item {
  background: var(--color-white);
  width: 70vw;
  transition: var(--transition);
  background: var(--color-200);
  border-radius: 4px;
  position: relative;
}

@media (min-width: 768px) {
  .services__item {
    width: auto;
    background: none;
  }
}

.services__icon {
  width: 16px;
}

@media (min-width: 768px) {
  .services__icon {
    width: 24px;
  }
}

.services__header {
  height: 40px;
  border-bottom: 1px solid var(--color-300);
  align-items: center;
  gap: 6px;
  padding: 0 10px;
  display: flex;
}

@media (min-width: 768px) {
  .services__header {
    height: auto;
    border-bottom: 0;
    margin-bottom: 20px;
    padding: 0;
  }
}

.services__title {
  color: var(--service-color);
  height: 100%;
  line-height: 40px;
  position: relative;
}

@media (min-width: 768px) {
  .services__title {
    font-size: 14px;
    line-height: 12px;
  }
}

.services__title:after {
  content: "";
  height: 1px;
  background: var(--service-color);
  position: absolute;
  bottom: -1px;
  left: -32px;
  right: 0;
}

@media (min-width: 768px) {
  .services__title:after {
    display: none;
  }
}

.services__list {
  flex-direction: column;
  margin-bottom: 8px;
  display: flex;
}

.services__list li {
  align-items: baseline;
  gap: 10px;
  display: flex;
}

.services__content {
  color: var(--color-700);
  padding: 16px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .services__content {
    padding: 0;
    font-size: 16px;
  }
}

.services__group-title {
  margin-bottom: 4px;
}

.services__dots {
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 20px 0;
  display: flex;
}

@media (min-width: 768px) {
  .services__dots {
    display: none;
  }
}

.services__dot {
  width: 5px;
  height: 5px;
  background: none;
  background: var(--color-400);
  border: 0;
  border-radius: 50%;
  outline: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.services__dot.active {
  background: var(--color-800);
}

.work {
  background: var(--color-500);
  padding: 40px 0 64px;
}

@media (min-width: 1024px) {
  .work {
    padding: 80px 0 128px;
  }
}

.work__header {
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  display: flex;
}

.work__subheading {
  color: var(--color-700);
}

@media (min-width: 768px) {
  .work__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .work__list {
    gap: 32px;
    transform: translateY(-5%);
  }
}

.work__item {
  cursor: pointer;
  padding-top: 40px;
}

@media (min-width: 768px) {
  .work__item {
    padding-top: 0;
  }
}

.work__top {
  z-index: 2;
  position: relative;
}

.work__title {
  margin-bottom: 12px;
}

.work__description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 16px;
  display: -webkit-box;
  overflow: hidden;
}

@media (min-width: 768px) {
  .work__description {
    margin-bottom: 24px;
  }
}

.work__img-wrap {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .work__img-wrap {
    margin-bottom: 24px;
  }
}

@media (min-width: 1024px) {
  .work__img-wrap {
    overflow: hidden;
  }
}

@media (min-width: 1024px) {
  .work__img {
    transition: var(--transition);
    transform: translateY(10%);
  }
}

.work__button {
  color: var(--color-main-600);
  align-items: center;
  gap: 8px;
  font-weight: 600;
  display: flex;
}

.work__button span {
  position: relative;
}

.work__button span:before {
  content: "";
  width: 100%;
  height: 1px;
  width: 100%;
  background: var(--color-main-600);
  transition: var(--transition);
  position: absolute;
  bottom: 0;
  transform: scaleX(0);
}

.work__item:hover .work__img {
  transform: translateY(0);
}

.work__item:hover .work__button span {
  position: relative;
}

.work__item:hover .work__button span:before {
  transform: scaleX(100%);
}

.partners {
  padding: 40px 0;
  overflow: hidden;
}

.partners__header {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  display: flex;
}

.partners__heading {
  color: var(--color-700);
}

.partners__list {
  width: 6000px;
  display: flex;
}

.partners__item {
  width: 300px;
  text-align: center;
  flex-shrink: 0;
}

@keyframes scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-3000px, 0, 0);
  }
}

.partners__list {
  animation: scroll 30s linear infinite;
}

.cases {
  border-top: 1px solid #0000000d;
  margin-top: 32px;
  padding-top: 8px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .cases {
    grid-template-columns: 1fr 2fr;
    padding: 20px 32px;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .cases {
    grid-template-columns: 1fr 1fr;
  }
}

.cases__heading {
  color: var(--color-muted);
  margin-bottom: 16px;
}

.cases__list {
  flex-flow: column wrap;
  gap: 12px;
  display: flex;
}

@media (min-width: 768px) {
  .cases__list {
    flex-direction: row;
  }
}

.cases__item {
  align-items: center;
  gap: 12px;
  display: flex;
}

@media (min-width: 768px) {
  .cases__item {
    width: calc(50% - 12px);
  }
}

.cases__title {
  color: var(--color-muted);
}

.reviews {
  background: #f7f5ef;
  padding: 40px 0 64px;
}

@media (min-width: 1024px) {
  .reviews {
    padding: 80px 0 128px;
  }
}

.reviews__heading {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .reviews__heading {
    margin-bottom: 32px;
  }
}

@media (min-width: 1024px) {
  .reviews__heading {
    margin-bottom: 64px;
  }
}

.reviews__header {
  align-items: center;
  gap: 4px;
  display: flex;
}

.reviews__item {
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
}

@media (min-width: 768px) {
  .reviews__item {
    background: none;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .reviews__item {
    gap: 40px;
    margin-bottom: 20px;
  }
}

.reviews__item:last-child {
  margin-bottom: 0;
}

.reviews__quote {
  color: var(--color-1000);
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.15;
  position: relative;
}

@media (min-width: 768px) {
  .reviews__quote {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  .reviews__quote {
    font-size: 48px;
    font-weight: 700;
  }
}

.reviews__text {
  color: var(--color-light);
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: clip;
  max-height: 40px;
  transition: var(--transition);
  -webkit-box-orient: vertical;
  margin-bottom: 12px;
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
}

@media (min-width: 768px) {
  .reviews__text {
    -webkit-line-clamp: unset;
    align-self: flex-end;
    font-size: 16px;
  }
}

.reviews__text p + p {
  margin-top: 24px;
}

.reviews__item--collapsed .reviews__text {
  -webkit-line-clamp: unset;
  overflow: unset;
  max-height: max-content;
}

.reviews__more {
  text-align: left;
  color: var(--color-main-600);
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.reviews__more:hover {
  color: var(--color-main-700);
}

@media (min-width: 768px) {
  .reviews__more {
    display: none;
  }
}

.author {
  color: var(--color-light);
  flex-shrink: 0;
  gap: 12px;
  margin-bottom: 16px;
  display: flex;
}

@media (min-width: 768px) {
  .author {
    flex-direction: row;
  }
}

.author__avatar {
  width: 48px;
  height: 48px;
  background: #e7e2d3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.author__name, .author__address {
  font-size: 14px;
}

@media (min-width: 768px) {
  .author__name, .author__address {
    font-size: 16px;
  }
}

.rate {
  border-top: 1px solid #00000014;
  border-bottom: 1px solid #00000014;
  margin: 30px 0;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .rate {
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid #00000014;
    margin: 0;
    padding: 0 0 0 48px;
  }
}

.rate__list {
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-end;
  gap: 12px;
  display: grid;
}

@media (min-width: 768px) {
  .rate__list {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: auto;
    gap: 32px;
  }
}

@media (min-width: 1024px) {
  .rate__list {
    gap: 48px;
  }
}

.rate__item {
  text-align: center;
}

@media (min-width: 768px) {
  .rate__item {
    text-align: left;
  }
}

.rate__title {
  color: var(--color-700);
  margin-bottom: 4px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .rate__title {
    font-size: 14px;
  }
}

.rate__text {
  font-size: 22px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .rate__text {
    font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .rate__text {
    font-size: 36px;
  }
}

.team {
  z-index: 1;
  padding-bottom: 80px;
}

.team__content {
  text-align: center;
  color: var(--color-700);
  max-width: 480px;
  margin: 0 auto;
}

.team__header {
  margin-bottom: 8px;
}

.team__heading {
  color: var(--color-1000);
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .team__heading {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  .team__heading {
    font-size: 48px;
  }
}

.team__button {
  color: var(--color-main-600);
  border: 0;
  border: 1px solid var(--color-main-600);
  text-transform: uppercase;
  background: none;
  border-radius: 4px;
  outline: none;
  margin: 20px 0;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.team__button:hover {
  background: var(--color-main-600);
  color: var(--color-white);
}

@media (min-width: 768px) {
  .team__button {
    margin: 40px 0 20px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .team__careers {
    gap: 8px;
    display: inline-flex;
  }
}

.team__members {
  height: 320px;
  position: relative;
  top: -20px;
}

@media (min-width: 768px) {
  .team__members {
    height: 420px;
  }
}

.team__link {
  color: var(--color-main-600);
  font-weight: 500;
}

.team__link span {
  position: relative;
}

.team__link span:before {
  content: "";
  width: 100%;
  height: 1px;
  width: 100%;
  background: var(--color-main-600);
  transition: var(--transition);
  position: absolute;
  bottom: 0;
  transform: scaleX(0);
}

.team__link:hover span:before {
  transform: scaleX(100%);
}

.member {
  background: var(--color-white);
  transform-origin: 50% 200%;
  transition: var(--transition);
  border-radius: 4px;
  flex-shrink: 0;
  position: absolute;
  left: 50%;
  overflow: hidden;
  box-shadow: 0 10px 20px #0000000a, 0 2px 6px #0000000a, 0 0 1px #0000000a;
}

.member:nth-child(1) {
  z-index: 2;
  transform: translateX(-30%)rotate(-32deg)scale(.95);
}

.member:nth-child(1) .member__img, .member:nth-child(1) .member__content {
  opacity: .2;
}

.member:nth-child(2) {
  z-index: 3;
  left: 50.5%;
  transform: translateX(-10%)rotate(-25deg);
}

.member:nth-child(2) .member__img, .member:nth-child(2) .member__content {
  opacity: .3;
}

.member:nth-child(3) {
  z-index: 4;
  transform: translateX(-40%)rotate(-10deg);
}

.member:nth-child(3) .member__img, .member:nth-child(3) .member__content {
  opacity: .7;
}

.member:nth-child(4) {
  text-align: center;
  z-index: 5;
  transform: translateX(-50%)rotate(0deg);
}

.member:nth-child(5) {
  text-align: right;
  z-index: 4;
  transform: translateX(-55%)rotate(10deg);
}

.member:nth-child(5) .member__img, .member:nth-child(5) .member__content {
  opacity: .7;
}

.member:nth-child(6) {
  text-align: right;
  z-index: 3;
  transform: translateX(-90%)rotate(25deg);
}

.member:nth-child(6) .member__img, .member:nth-child(6) .member__content {
  opacity: .3;
}

.member:nth-child(7) {
  text-align: right;
  z-index: 2;
  transform: translateX(-70%)rotate(32deg)scale(.95);
}

.member:nth-child(7) .member__img, .member:nth-child(7) .member__content {
  opacity: .2;
}

.team__members:hover .member {
  transform-origin: 50% 260%;
}

.team__members:hover .member .member__img, .team__members:hover .member .member__content {
  opacity: 1;
}

.member__content {
  transition: var(--transition);
  padding: 16px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .member__content {
    font-size: 16px;
  }
}

.member__name {
  color: var(--color-1000);
  margin-bottom: 4px;
  font-weight: 700;
}

.member__job {
  color: var(--color-900);
}

.member__img {
  transition: var(--transition);
  width: 140px;
  height: 210px;
}

@media (min-width: 768px) {
  .member__img {
    width: 180px;
    height: 270px;
  }
}

.why {
  padding: 40px 0;
  position: relative;
}

@media (min-width: 1024px) {
  .why {
    padding: 120px 0;
  }
}

.why__bg {
  min-height: 100vh;
  opacity: .5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.why__bg:after {
  content: "";
  height: 500px;
  z-index: 1;
  background: linear-gradient(to right, #fff 5%, #0000 25% 50%, #0000 75%, #fff 95%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.why__container {
  z-index: 1;
  position: relative;
}

.why__content {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 768px) {
  .why__content {
    grid-template-columns: repeat(2, 50%);
    padding: 30px 0 0;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .why__content {
    padding: 40px 0;
  }
}

.why__header {
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  display: flex;
}

@media (min-width: 768px) {
  .why__header {
    margin-bottom: 12px;
  }
}

.why__subheading {
  color: var(--color-850);
}

.why__heading {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .why__text {
    padding-right: 40px;
  }
}

@media (min-width: 1024px) {
  .why__text {
    padding-right: 80px;
  }
}

.why__text p + p {
  margin-top: 16px;
}

.contact {
  z-index: 5;
  padding: 40px 0;
  position: relative;
}

@media (min-width: 768px) {
  .contact {
    padding: 40px 0 120px;
  }
}

@media (min-width: 1024px) {
  .contact {
    padding: 120px 0;
  }
}

@media (min-width: 768px) {
  .contact__container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.contact__heading {
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  display: flex;
}

.contact__subheading {
  color: var(--color-700);
}

.contact__top {
  margin-bottom: 40px;
}

.contact__text {
  font-size: 22px;
}

@media (min-width: 768px) {
  .contact__text {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  .contact__text {
    font-size: 48px;
  }
}

.contact__button {
  background: none;
  background: var(--color-main-600);
  font-size: 16px;
  font-family: var(--font-family);
  color: var(--color-white);
  border: 0;
  border: 1px solid var(--color-main-600);
  height: 48px;
  text-align: center;
  min-width: 160px;
  border-radius: 4px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0;
  padding: 0;
  font-weight: bold;
  display: flex;
  box-shadow: 0 4px 4px #0000000d;
}

.contact__button:hover {
  background: var(--color-white);
  color: var(--color-main-600);
}

footer {
  background: var(--color-1000);
  color: var(--color-600);
  padding: 48px 0;
  font-size: 14px;
  line-height: 1.4;
}

@media (min-width: 768px) {
  footer {
    padding: 80px 0 48px;
    font-size: 16px;
  }
}

.footer__address {
  line-height: 1.55;
}

.footer__content {
  grid-template-rows: auto auto;
  gap: 48px;
  margin: 16px 0;
  display: grid;
}

@media (min-width: 768px) {
  .footer__content {
    grid-template-rows: auto;
    grid-template-columns: auto auto;
  }
}

.footer__links {
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  display: grid;
}

@media (min-width: 768px) {
  .footer__links {
    justify-items: end;
    gap: 0;
  }
}

.footer__link {
  color: var(--color-white);
}

.footer__nav {
  border-top: 1px solid #ffffff04;
  border-bottom: 1px solid #ffffff04;
  flex-direction: column;
  gap: 16px;
  margin: 30px 0;
  padding: 30px 0;
  display: flex;
}

@media (min-width: 768px) {
  .footer__nav {
    border: 0;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }
}

.footer__copyright {
  text-align: center;
  flex-direction: column;
  gap: 8px;
  display: flex;
}

@media (min-width: 768px) {
  .footer__copyright {
    flex-direction: row;
    flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .footer__bottom {
    border-top: 1px solid #ffffff04;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 120px;
    padding-top: 24px;
    display: flex;
  }
}

.modals {
  width: 300vw;
  height: 100vh;
  z-index: 10;
  background: var(--color-200);
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  position: fixed;
  top: 0;
}

.modal__close {
  z-index: 20;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 0;
  border-radius: 50%;
  outline: none;
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
}

.modal {
  color: var(--color-1000);
  background: var(--color-200);
  cursor: default;
  height: 100vh;
  padding: 64px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  overflow-y: auto;
}

.modal:before {
  content: "";
  height: 100px;
  z-index: 10;
  background: linear-gradient(to bottom, var(--color-200) 0%, var(--color-200) 50%, #fff0 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.modal::-webkit-scrollbar {
  width: 4px;
}

.modal::-webkit-scrollbar-thumb {
  background: var(--color-main-600);
  border-radius: 8px;
}

@media (min-width: 768px) {
  .modal {
    font-size: 16px;
  }
}

.modal__wrapper {
  height: 100vh;
  cursor: zoom-out;
  background: #00000020;
  position: relative;
}

.modal__heading {
  margin: 32px 0 8px;
  font-size: 22px;
}

@media (min-width: 768px) {
  .modal__heading {
    margin: 32px 0 20px;
    font-size: 36px;
  }
}

.modal__aside {
  border-style: solid;
  border-width: 1px 0;
  border-color: var(--color-100);
  border-radius: 4px;
  order: 1;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 0 32px;
  display: grid;
}

@media (min-width: 768px) {
  .modal__aside {
    width: 30%;
    border-width: 0 0 0 1px;
    grid-auto-rows: max-content;
    gap: 32px;
    margin: 0 40px;
    padding: 0 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.modal__description {
  order: 0;
}

@media (min-width: 768px) {
  .modal__description {
    font-size: 24px;
  }
}

.modal__title {
  color: var(--color-700);
  margin-bottom: 8px;
}

.modal__text + .modal__text {
  margin-top: 4px;
}

.modal__paragraph {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .modal__paragraph {
    margin-top: 32px;
  }
}

.modal__main {
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .modal__main {
    padding-right: 40%;
    display: block;
  }
}

.modal__content {
  order: 3;
}

.modal__gallery {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
}

@media (min-width: 768px) {
  .modal__gallery {
    padding-top: 40px;
  }
}

@media (min-width: 1024px) {
  .modal__gallery {
    padding-top: 80px;
  }
}

.modal__img {
  cursor: zoom-in;
  width: calc(50% - 10px);
}

@media (min-width: 768px) {
  .modal__img {
    width: calc(25% - 20px);
  }
}

.modal__img.x2 {
  display: none;
}

@media (min-width: 768px) {
  .modal__img.x2 {
    width: calc(50% - 20px);
    display: block;
  }
}

.modal__img img {
  display: block;
}

.next {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px 0;
  display: flex;
}

.next__label {
  color: var(--color-800);
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  display: flex;
}

.next__title {
  color: var(--color-main-600);
  transition: var(--transition);
  font-size: 16px;
  font-weight: 700;
}

.next:hover .next__title {
  color: var(--color-main-700);
}

.gallery {
  z-index: 20;
  cursor: zoom-out;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
  position: fixed;
  inset: 0;
}

.gallery__bg {
  z-index: 1;
  background: #00000050;
  position: absolute;
  inset: 0;
}

#galleryContent {
  z-index: 2;
  max-height: 100%;
  max-width: 100%;
  display: block;
  position: relative;
}

/*# sourceMappingURL=index.276732c0.css.map */

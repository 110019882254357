.team {
  padding-bottom: 80px;
  z-index: 1;
}

.team__content {
  text-align: center;
  color: var(--color-700);

  max-width: 480px;
  margin: 0 auto;
}

.team__header {
  margin-bottom: 8px;
}

.team__heading {
  font-size: 22px;
  font-weight: 700;
  color: var(--color-1000);
  margin-bottom: 16px;

  @include media-tablet {
    font-size: 36px;
  }
  @include media-desktop {
    font-size: 48px;
  }
}

.team__button {
  @include btn-reset;

  display: inline-block;
  color: var(--color-main-600);
  border: 1px solid var(--color-main-600);
  padding: 8px 16px;
  border-radius: 4px;
  margin: 20px 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;

  &:hover {
    background: var(--color-main-600);
    color: var(--color-white);
  }

  @include media-tablet {
    font-size: 16px;
    margin: 40px 0px 20px;
  }
}

.team__careers {
  @include media-tablet {
    display: inline-flex;
    gap: 8px;
  }
}

.team__members {
  position: relative;
  top: -20px;
  height: 320px;

  @include media-tablet {
    height: 420px;
  }
}

.team__link {
  color: var(--color-main-600);
  font-weight: 500;

  span {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      width: 100%;
      transform: scaleX(0);
      background: var(--color-main-600);
      transition: var(--transition);
    }
  }

  &:hover {
    span {
      &::before {
        transform: scaleX(100%);
      }
    }
  }
}
